<template>
  <v-container>
    <v-text-field
      v-if="isAddText"
      v-model="newTask"
      label="Agregar nueva"
      @keydown.enter="create"
      class="pt-0"
    >
      <template v-slot:append>
        <v-fade-transition>
          <v-icon v-if="newTask" @click="create" >
            mdi-plus-circle
          </v-icon>
        </v-fade-transition>
      </template>
    </v-text-field>
    <v-divider class="mt-4"></v-divider>
    <v-row class="my-1" align="center" v-if="isAddCheck">
      <strong class="mx-4 info--text text--darken-2">
        Restantes: {{ remainingTasks }}
      </strong>
      <v-divider vertical></v-divider>
      <strong class="mx-4 success--text text--darken-2">
        Completado: {{ completedTasks }}
      </strong>
      <v-spacer></v-spacer>
      <v-progress-circular
        color="primary"
        :value="progress"
        class="mr-2 d-none d-sm-block"
      ></v-progress-circular>
    </v-row>
    <v-divider class="mb-4"></v-divider>
    <div v-if="list.length > 0">
      <v-slide-y-transition class="py-0" group>
        <div v-for="(task, i) in list" :key="i">
          <v-divider v-if="i !== 0"></v-divider>
          <v-list-item class="pa-0">
            <v-list-item-action>
              <v-checkbox
                v-if="isAddCheck"
                v-model="task.done"
                @change="changeSubTask"
                :color="task.done && 'grey' || 'primary'"
              >
                <template v-slot:label>
                  <div
                    :class="task.done && 'grey--text' || 'primary--text'"
                    class="ml-4"
                    v-text="task.text"
                  ></div>
                </template>
              </v-checkbox>
              <div
                v-else
                :class="task.done && 'grey--text' || 'primary--text'"
                class="ml-4"
                v-text="task.text"
              ></div>
            </v-list-item-action>
            <v-spacer></v-spacer>
            <v-scroll-x-transition>
              <v-icon v-if="task.done" color="success">
                mdi-check
              </v-icon>
            </v-scroll-x-transition>
            <v-list-item-action v-if="isRemoveCheck">
              <v-btn color="secondary" icon small @click="remove(i)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </div>
      </v-slide-y-transition>
    </div>
  </v-container>
</template>

<script>
import apiEmployeeTasks from '@/api/employeeTasks'
export default {
  name: 'CheckListTask',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    isAddCheck: {
      type: Boolean,
      default: false
    },
    isRemoveCheck: {
      type: Boolean,
      default: true
    },
    isAddText: {
      type: Boolean,
      default: true
    },
    taskId: {
      type: String
    }
  },
  data: () => ({
    tasks: [],
    newTask: null
  }),
  computed: {
    completedTasks () {
      return this.list.filter(task => task.done).length
    },
    progress () {
      return this.completedTasks / this.list.length * 100
    },
    remainingTasks () {
      return this.list.length - this.completedTasks
    }
  },
  methods: {
    create () {
      if (this.newTask.trim()) {
        this.$emit('addCheck', {
          done: false,
          text: this.newTask.trim()
        })
        this.newTask = null
      }
    },
    remove (i) {
      this.$emit('removeCheck', i)
    },
    async changeSubTask () {
      console.log(this.list, this.taskId)
      const subtasks = await apiEmployeeTasks.updateSubtask(this.taskId, { checklist: this.list })
      console.log(subtasks)
    }
  }
}
</script>
