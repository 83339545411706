<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :max-width="maxWidth"
    >
      <v-card>
        <v-toolbar class="box-shadow-none">
          <v-toolbar-title>Eliminar {{ name }} ?</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>Está seguro de eliminar {{ name }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="deleted"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'ModalDelete',
  props: ['visible', 'name', 'maxWidth'],
  computed: {
    dialog: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) this.$emit('closeModalDelete')
      }
    }
  },
  methods: {
    deleted () {
      this.dialog = false
      this.$emit('deletedItem')
    }
  }
}
</script>
<style scoped>
.box-shadow-none {
  box-shadow: none !important;
}
</style>
