<template>
  <div>
    <div :class="`d-flex align-center ${ hasName ? 'justify-space-between': 'justify-end' }`">
      <v-row>
        <v-col class="pb-0" cols="12" md="6">
          <div v-if="hasName" class="d-flex align-center">
            <v-avatar size="56">
              <img alt="user" :src="this.loadAvatar(dataEmployee.avatar)">
            </v-avatar>
            <p class="ml-4 text-h6 text--secondary mb-0">{{ dataEmployee.name }} {{ dataEmployee.lastName }}</p>
          </div>
        </v-col>
        <v-col class="d-flex align-center justify-end pb-0" cols="12" md="6">
          <v-btn v-if="isDateGreater" outlined color="primary" dark @click="openCreateTask()">
          Agregar Tarea
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <template v-if="tasks.length > 0">
      <v-row class="mt-7">
        <v-col cols="12" v-for="(task, index) in tasks" :key="index">
          <v-card outlined>
            <v-card-text class="text--primary py-3">
              <div class="d-flex">
                <div class="d-flex mt-10 mt-sm-0">
                  <div>
                    <v-menu open-on-hover top offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{ task.taskOption.name }}</span>
                      </template>
                      <v-card>
                        <v-card-text>
                          {{ task.taskOption.description }}
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                  <div class="ml-3">
                    <span>{{ task.place?.name }}</span>
                  </div>
                </div>
                <v-spacer></v-spacer>
                <div class="bnt-edits">
                  <v-tooltip
                    v-if="(!user.permissions.some(p => p.value === permissions[0]) && !task.transaction.some(s => s.status === 'completed' || s.status === 'reviewed' || s.status === 'cancel'))
                    || (user.permissions.some(p => p.value === permissions[0]) && !task.transaction.some(s => s.status === 'reviewed' || s.status === 'cancel'))"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        small
                        class="mr-2"
                        @click="openStatusTask(task)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-autorenew</v-icon>
                      </v-btn>
                    </template>
                    <span>Cambiar Status</span>
                  </v-tooltip>
                  <v-tooltip top v-if="task.taskOption.taskEspecial?.name == 'revision-inventario'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        small
                        class="mr-2"
                        @click="navigateReview(task)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-clipboard-list</v-icon>
                      </v-btn>
                    </template>
                    <span>Revisar Inventario</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        small
                        class="mr-2"
                        @click="openPhotoTask(task)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-camera</v-icon>
                      </v-btn>
                    </template>
                    <span>Fotos</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        small
                        class="mr-2"
                        @click="openEditTask(task)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        small
                        @click="deleteTask(task._id)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </div>
              </div>
            </v-card-text>
            <v-card-text class="py-0">
              {{ task.description }}
            </v-card-text>
            <v-card-text class="pb-0">
              <v-row>
                <v-col class="fit-content">
                  {{ formatHours(task.dateStart) }} a {{ formatHours(task.dateEnd) }}
                </v-col>
                <v-col cols="12" md="auto">
                  <v-chip outlined :color="status.find(x => x.value == task.status).color" small class="mr-5" @click="openStatusTask(task)">
                    {{ status.find(x => x.value == task.status).title }}
                  </v-chip>
                  <v-chip outlined :color="priorities.find(x => x.value == task.priority).color" small class="min-width-70">
                    {{ priorities.find(x => x.value == task.priority).title }}
                  </v-chip>
                </v-col>
                <!-- <v-col cols="auto">
                  <v-menu open-on-hover top offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">123456</span>
                    </template>
                    <v-card>
                      <v-card-text>
                        código de la reserva
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-col> -->
                <v-col>
                  <v-menu offset-y v-if="task.taskOption.type === 'cleaning' && windowWidth < 960">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn :color="cleaned.find(c => c.value === task.place.cleaned).color" dark v-bind="attrs" v-on="on" small>
                        {{ cleaned.find(c => c.value === task.place.cleaned).title }}
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-for="(item, index) in cleanedOptions" :key="index" @click="changeCleaned(task.place._id, item.value, task)">
                        <div :style="`background-color: ${item.color};`" class="color-cleaned"></div>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <div class="float-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          color="primary"
                          class="pa-0"
                          @click="openTransaction(index)"
                          plain
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon >mdi-format-list-bulleted-square</v-icon>
                          <v-icon right>
                            <template v-if="task.isTransaction">mdi-chevron-down</template>
                            <template v-else>mdi-chevron-right</template>
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Historial de status</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-badge
                          v-if="task.checklist.length > 0"
                          bordered
                          bottom
                          color="deep-orange accent-4"
                          dot
                          offset-x="30"
                          offset-y="0"
                          overlap
                        ></v-badge>
                          <v-btn
                            class="ml-2 pa-0"
                            small
                            color="primary"
                            @click="openChecklist(index)"
                            plain
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon >mdi-order-bool-ascending-variant</v-icon>
                            <v-icon right>
                              <template v-if="task.isChecklist">mdi-chevron-down</template>
                              <template v-else>mdi-chevron-right</template>
                            </v-icon>
                          </v-btn>
                      </template>
                      <span>Sub Tareas</span>
                    </v-tooltip>
                  </div>
                </v-col>
                <!-- <v-col cols="12" class="pt-0" >
                  <v-select :items="cleaned"  item-text="title" item-value="value" outlined dense hide-details class="select-cleaned">
                    <template v-slot:selection="{ item }">
                        <span :style="`background-color: ${item.color};`"></span>
                        <span>{{ item.title }}</span>
                      </template>
                    </v-select>
                </v-col> -->
              </v-row>
            </v-card-text>
            <v-card-text class="pt-1 d-flex align-center">
              <v-menu offset-y v-if="task.taskOption.type === 'cleaning' && windowWidth > 959">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :color="cleaned.find(c => c.value === task.place.cleaned).color" dark v-bind="attrs" v-on="on" small class="mr-2">
                    {{ cleaned.find(c => c.value === task.place.cleaned).title }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in cleanedOptions" :key="index" @click="changeCleaned(task.place._id, item.value, task)">
                    <div :style="`background-color: ${item.color};`" class="color-cleaned"></div>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <template v-if="(!user.permissions.some(p => p.value === permissions[0]) && !task.transaction.some(s => s.status === 'pending' || s.status === 'completed' || s.status === 'reviewed' || s.status === 'cancel'))
                || (user.permissions.some(p => p.value === permissions[0]) && !task.transaction.some(s => s.status === 'pending' || s.status === 'reviewed' || s.status === 'cancel'))"
              >
                <v-btn depressed small color="primary" @click="openDoor(task)" :disabled="disabledOpenDoor">Abrir puerta</v-btn>
              </template>
              <div class="font-weight-black red--text" v-if="task.place.booking?.dontDisturb">No molestar</div>
            </v-card-text>
            <v-expand-transition>
              <v-card-text class="pt-0" v-show="task.isTransaction">
                <Transaction :list="task.transaction"></Transaction>
              </v-card-text>
            </v-expand-transition>
            <v-expand-transition>
              <v-card-text class="pt-0" v-show="task.isChecklist">
                <Checklist :list="task.checklist" :isAddCheck="true" :isRemoveCheck="false" :isAddText="false" :taskId="task._id">
                </Checklist>
              </v-card-text>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <v-img
      v-else
      height="520"
      alt="Vuetify Logo"
      class="shrink mr-2 mt-10"
      contain
      :src="require('@/assets/img/todo-empty-state.svg')"
      transition="scale-transition"
    />

    <TasksModal
      @closeTask="closeTask"
      @dialogTaskClose="dialog=false"
      @submitTask="submitTask"
      @isClearModalFalse="isClearModal=false"
      :dialog="dialog"
      :area="dataEmployee.area"
      :formTask="formTask"
      :btnName="btnName"
      :isClear="isClearModal"
      :employeeId="employeeId"
      :taskType="taskType"
    >
    </TasksModal>
    <ModalDelete :visible="shoModalDelete" @closeModalDelete="shoModalDelete=false" name="la tarea" maxWidth="400" @deletedItem="deletedItem">
    </ModalDelete>
    <StatusTask
      :statusTransition="statusTransition"
      :visible="shoModalStatus"
      :items="items"
      :place="place"
      :taskId="taskId"
      :business="business"
      :isService="isService"
      maxWidth="600"
      @closeModalStatusTask="shoModalStatus=false"
      @changeStatus="changeStatus"
    >
    </StatusTask>
    <Photo
      :visible="shoModalPhoto"
      maxWidth="600"
      :task="selectedTask"
      @closeModalPhoto="shoModalPhoto=false"
      @updatePhoto="updatePhoto"
    >
    </Photo>
    <!-- <InventoryReview
      :visible="shoModalInventoryReview"
      :place="place"
      :isScroll="isScroll"
      :dateSelected="dateSelected"
      maxWidth="600"
      @closeModalInventoryReview="shoModalInventoryReview=false"
      @setIsScroll="setIsScroll"
    >
    </InventoryReview> -->
  </div>
</template>

<script>
import apiEmployeeTasks from '@/api/employeeTasks'
import apiEmployeeTasksOptions from '@/api/employeeTasksOptions'
import apiPlaces from '@/api/places'
import Transaction from '@/components/Shared/task/TaskTransaction.vue'
import Checklist from '@/components/Shared/task/CheckListTask.vue'
import StatusTask from '@/components/Shared/task/StatusTask.vue'
import Photo from '@/components/Shared/task/PhotoModal.vue'
// import InventoryReview from '@/components/Shared/task/InventoryReview.vue'
import ModalDelete from '@/components/Shared/ModalDelete.vue'
import TasksModal from '@/components/Shared/task/TasksModal.vue'
import { format } from 'date-fns'
import { imageURL, waitFor } from '@/utils'
import { mapGetters, mapMutations } from 'vuex'
import permissions from '@/constants/permissions'
import socketioService from '@/services/socketioService'
export default {
  components: { Transaction, Checklist, StatusTask, ModalDelete, TasksModal, Photo },
  props: ['employeeId', 'hasName', 'tasks', 'workdays', 'selectWorday', 'isClear', 'dataEmployee', 'dateSelected', 'isDateGreater', 'windowWidth'],
  data: (vm) => ({
    dialog: false,
    status: [
      { color: '#fb8f00', title: 'Abierto', value: 'open' },
      { color: '#ffce31', title: 'Pendiente', value: 'pending' },
      { color: '#ed4c5c', title: 'Cancelado', value: 'cancel' },
      { color: '#43c4e3', title: 'En proceso', value: 'process' },
      { color: '#00a790', title: 'Completado', value: 'completed' },
      { color: '#b01aff', title: 'Revisado', value: 'reviewed' }
    ],
    priorities: [
      { color: '#ed4c5c', title: 'Muy alto', value: 'very-high' },
      { color: '#ffce31', title: 'Alto', value: 'high' },
      { color: '#00a790', title: 'Normal', value: 'normal' },
      { color: '#43c4e3', title: 'Bajo', value: 'low' },
      { color: '#b01aff', title: 'Muy bajo', value: 'very-low' }
    ],
    cleaned: [
      { color: 'red', title: 'Sucio', value: 'dirty' },
      { color: 'green', title: 'Limpio', value: 'cleaned' },
      { color: 'purple', title: 'Revisado', value: 'checked' }
    ],
    cleanedOptions: [
      { color: 'red', title: 'Sucio', value: 'dirty' },
      { color: 'green', title: 'Limpio', value: 'cleaned' }
    ],
    formTask: {
      taskOption: null,
      place: null,
      employee: vm.employeeId,
      priority: 'normal',
      dateStart: null,
      dateEnd: null,
      checklist: [],
      description: '',
      status: 'pending'
    },
    btnName: 'Agregar',
    shoModalStatus: false,
    shoModalPhoto: false,
    shoModalInventoryReview: false,
    taskId: '',
    taskIdDelete: '',
    place: '',
    shoModalDelete: false,
    isClearModal: false,
    isScroll: false,
    statusTransition: [],
    user: JSON.parse(localStorage.getItem('user')),
    permissions: permissions,
    items: [],
    business: null,
    taskType: null,
    disabledOpenDoor: false,
    isService: false,
    selectedTask: { photos: [] }
  }),
  created () {
    if (this.user.permissions.some(p => p.value === permissions[2])) {
      this.cleanedOptions.push({ color: 'purple', title: 'Revisado', value: 'checked' })
    }
  },
  computed: {
    ...mapGetters(['reponseScanner'])
  },
  methods: {
    ...mapMutations(['clearReviewItems', 'setIdQR', 'setSnackbar']),
    submitTask (data) {
      console.log(this.taskId, 'this.taskId')
      if (this.taskId !== '') {
        this.updateTask(data)
      } else {
        this.addTask(data)
      }
    },
    openCreateTask () {
      this.dialog = true
      this.taskId = ''
    },
    async addTask (data) {
      this.dialog = false
      const dataTask = await this.createTask(data)
      this.$emit('addTask', { dataTask, formTask: data })
    },
    async updateTask (formData) {
      try {
        const body = { ...formData }
        body.place = body.place._id
        body.taskOption = body.taskOption._id
        const data = await apiEmployeeTasks.update(this.taskId, body)
        console.log(data)
        this.$emit('updateTask', { formTask: formData, taskId: this.taskId })
        this.dialog = false
      } catch (err) {
        const msg = err.response.data?.err === 'No Permission' ? 'Acceso no autorizado' : null
        this.setSnackbar({ show: true, text: msg || err.response.data.err || err.response.data.message, color: 'red' })
      }
    },
    deleteTask (id) {
      this.taskIdDelete = id
      this.shoModalDelete = true
    },
    async deletedItem () {
      this.$emit('deletedItem', this.taskIdDelete)
    },
    async createTask (formData) {
      const body = { ...formData }
      body.place = body.place._id
      body.taskOption = body.taskOption._id
      const w = this.workdays.find(w => w.id === this.selectWorday)
      body.dateStart.setFullYear(w.year)
      body.dateStart.setMonth(w.month)
      body.dateStart.setDate(w.date)
      body.dateEnd.setFullYear(w.year)
      body.dateEnd.setMonth(w.month)
      body.dateEnd.setDate(w.date)
      const data = await apiEmployeeTasks.create(body)
      return data.data.data
    },
    async openEditTask (data) {
      console.log('data', data)
      this.btnName = 'Editar'
      this.taskId = data._id
      this.formTask.dateStart = data.dateStart
      this.formTask.dateEnd = data.dateEnd
      this.formTask.priority = data.priority
      this.formTask.status = data.status
      this.formTask.description = data.description
      this.formTask.taskOption = data.taskOption
      this.formTask.place = data.place
      this.taskType = data.type
      if (data.checklist.length > 0) {
        console.log(data.checklist, 'data.checklist')
        this.formTask.checklist = data.checklist
      }
      this.dialog = true
    },
    async changeStatus (data) {
      try {
        const res = await apiEmployeeTasks.updateStatus(this.taskId, { task: this.taskId, ...data })
        const dataTask = this.tasks.find(t => t._id === this.taskId)
        console.log(dataTask, 'dataTask')
        if (data.status === 'process') {
          socketioService.getSocket().emit('add_fingerprint', { user: dataTask.employee, place: dataTask.place._id, start: dataTask.dateStart, end: dataTask.dateEnd })
        } else if (data.status === 'completed') {
          socketioService.getSocket().emit('delete_one_fingerprint_place', { user: dataTask.employee, place: dataTask.place._id })
        } else if (data.status === 'cancel' && dataTask.status === 'process') {
          socketioService.getSocket().emit('delete_one_fingerprint_place', { user: dataTask.employee, place: dataTask.place._id })
        }
        this.$emit('changeStatus', { status: data.status, id: this.taskId, employeeTaskTransaction: res.data.data.employeeTaskTransaction })
      } catch (err) {
        const msg = err.response.data?.err === 'No Permission' ? 'Acceso no autorizado' : null
        this.setSnackbar({ show: true, text: msg || err.response.data.err || err.response.data.message, color: 'red' })
      }
    },
    openTransaction (index) {
      this.$emit('openTransaction', index)
    },
    openChecklist (index) {
      this.$emit('openChecklist', index)
    },
    loadAvatar (avatar) {
      return avatar ? imageURL(avatar, 'xs') : require('@/assets/img/user.png')
    },
    formatHours (date) {
      return format(date, 'hh:mm a')
    },
    openStatusTask (task) {
      console.log(task, 'task')
      if ((!this.user.permissions.some(p => p.value === this.permissions[0]) && !task.transaction.some(s => s.status === 'completed' || s.status === 'reviewed' || s.status === 'cancel')) ||
        (this.user.permissions.some(p => p.value === this.permissions[0]) && !task.transaction.some(s => s.status === 'reviewed' || s.status === 'cancel'))) {
        this.place = task.place._id
        this.loadItems(task.taskOption._id)
        this.statusTransition = task.transaction.map(t => t.status)
        this.taskId = task._id
        this.isService = task.taskOption.service || false
        this.shoModalStatus = true
      }
    },
    openPhotoTask (task) {
      console.log(task)
      this.selectedTask = task
      this.shoModalPhoto = true
    },
    async loadItems (id) {
      const { data: { data } } = await apiEmployeeTasksOptions.detail(id)
      console.log('loadItems', data)
      this.items = data.items
      this.business = data.area?.business
    },
    openInventoryReview (task) {
      this.place = task.place._id
      this.shoModalInventoryReview = true
    },
    nameErrors (name, nameErrors) {
      const { required = false, maxLength = false, length = 0 } = nameErrors
      const errors = []
      if (!this.$v.formTask[name].$dirty) return errors
      if (required) !this.$v.formTask[name].required && errors.push('Campo es requiredo.')
      if (maxLength) !this.$v.formTask[name].maxLength && errors.push(`Debe tener como máximo ${length} caracteres`)
      return errors
    },
    notBeforeNineOClock (name, date) {
      if (name === 'dateStart' && this.formTask.dateStart != null) {
        return date.getTime() < this.formTask.dateStart.getTime()
      } else if (name === 'dateEnd' && this.formTask.dateEnd != null) {
        return date.getTime() > this.formTask.dateEnd.getTime()
      }
      return false
    },
    closeTask () {
      this.clearTask()
      this.dialog = false
    },
    clearTask () {
      this.formTask = {
        taskOption: null,
        place: null,
        employee: this.formTask.employee,
        priority: 'normal',
        dateStart: null,
        dateEnd: null,
        checklist: [],
        description: '',
        status: 'pending'
      }
      this.taskType = null
      this.btnName = 'Agregar'
      this.taskId = ''
    },
    setIsScroll () {
      this.isScroll = true
    },
    navigateReview (task) {
      this.clearReviewItems()
      this.setIdQR('')
      this.$router.push({
        path: `/tasks/review-inventory/${task.place._id}/${task._id}`,
        query: {
          day: this.selectWorday,
          startWeek: this.dateSelected.startWeek.toISOString(),
          endWeek: this.dateSelected.endWeek.toISOString()
        }
      })
    },
    async openDoor (task) {
      this.disabledOpenDoor = true
      socketioService.getSocket().emit('open_door_scanner', { user: this.user._id, place: task.place._id })
      await waitFor(10000)
      this.disabledOpenDoor = false
    },
    async updatePhoto (data) {
      console.log('data con la imagen', data)
      // this.selectedTask.taskOption.name = 'hola'
      if (data.push) {
        this.selectedTask.photos.push(data.image)
      } else {
        this.selectedTask.photos.splice(data.i, 1)
      }
      await apiEmployeeTasks.update(this.selectedTask._id, { photos: this.selectedTask.photos })
    },
    async changeCleaned (id, value, task) {
      console.log(id, value)
      task.place.cleaned = value
      await apiPlaces.updateCleaned(id, value)
    }
  },
  watch: {
    isClear (val) {
      if (val) {
        this.isClearModal = true
        this.clearTask()
        this.$emit('isClearFalse')
      }
    },
    reponseScanner (val) {
      this.setSnackbar({ show: true, text: 'se ha abierto la puerta', color: 'green' })
    }
  }
}
</script>
<style scoped>
.fit-content {
  max-width: 174px !important;
}
.bnt-edits {
  position: absolute;
  left: 15px;
  right: 10px;
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 600px) {
  .bnt-edits {
    width: auto;
    left: auto;
  }
}
.select-cleaned {
  width: 150px;
}
.color-cleaned {
  width: 12px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}
</style>
