<template>
  <div class="pl-4">
    <h2 class="d-inline-block primary--text font-weight-regular ">{{title}}</h2>
    <v-breadcrumbs class="d-inline-block" :items="items"></v-breadcrumbs>
  </div>
</template>

<script>
// import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'AppBar',
  props: ['title'],
  data: () => ({
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: 'breadcrumbs_dashboard'
      },
      {
        text: 'Link 1',
        disabled: false,
        href: 'breadcrumbs_link_1'
      },
      {
        text: 'Link 2',
        disabled: true,
        href: 'breadcrumbs_link_2'
      }
    ]
  }),
  computed: {
  },
  methods: {
  }
}
</script>
