const data = [
  'writeTaskReviewed',
  'readTaskRequestAssign',
  'writeTaskReviewedCleaned'
  // 'Consultar tareas',
  // 'Consultar tareas propias',
  // 'Crear tareas programadas',
  // 'Crear tareas de mantenimiento correctivo',
  // 'Consultar todas las solicitudes de tareas',
  // 'Consultar solicitudes de tareas del área',
  // 'Consultar solicitudes de tareas propias',
  // 'Asignar trabajador a la solicitud de tarea'

  // Actualizar tareas
  // Asignar trabajador a la solicitud de tarea
  // Consultar solicitudes de tareas del área
  // Consultar solicitudes de tareas propias
  // Consultar tareas
  // Consultar tareas programadas
  // Consultar tareas propias
  // Consultar todas las solicitudes de tareas
  // Crear Tareas
  // Crear tareas de mantenimiento correctivo
  // Crear tareas programadas
  // Eliminar Tareas
]
export default data
