import axios from '@/services/axiosService'

export default {
  async detail (id) {
    return axios().get(`/tasks-options/detail/${id}`)
  },
  async list (id, params = {}) {
    return axios().get(`/tasks-options/list/area/${id}`, { params: { deleted: false, ...params } })
  }
}
