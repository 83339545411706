<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" :max-width="maxWidth">
      <v-card>
        <v-toolbar class="box-shadow-none">
          <v-toolbar-title> Fotos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-btn block color="primary" dark @click="onThumbClick(0)" class="mb-4">Ver Fotos</v-btn>
          <v-row class="item-images ma-0">
            <v-col cols="auto" class="pa-0" v-for="(p,i) in task.photos" :key="i">
              <div class="content-img">
                <img :src="loadAvatar(p)" alt="" height="80px" width="80px" >
                <v-btn @click="removePhoto(p, i)" color="secondary" fab x-small class="btn-img">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col class="pa-0">
              <div class="content-img">
                <img src="@/assets/img/camera.png" alt="" height="80px" width="80px">
                <v-btn @click="addPhoto" color="secondary" fab x-small class="btn-img">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <LightGallery
            :images="images"
            :index="photoIndex"
            :disable-scroll="true"
            @close="handleHide()"
          />
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1">
            Cambiar
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import cameraService from '@/services/cameraService'
import { LightGallery } from 'vue-light-gallery'
import { imageURL } from '@/utils'
import documentUploadService from '@/services/documentUploadService'
export default {
  name: 'PhotoModal',
  props: ['visible', 'maxWidth', 'task'],
  components: { LightGallery },
  computed: {
    dialog: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) this.$emit('closeModalPhoto')
      }
    },
    images () {
      return this.task.photos.map(p => ({ url: this.loadAvatar(p, 'original') }))
    }
  },
  data: () => ({
    photoIndex: null
  }),
  methods: {
    async addPhoto () {
      const image = await cameraService.takePicture()
      const photo = await this.uploadPhoto(image)
      if (photo) this.$emit('updatePhoto', { push: true, image: photo })
    },
    async removePhoto (p, i) {
      const doc = await documentUploadService.deleteDocument(p, 'image')
      if (doc.data.ok) this.$emit('updatePhoto', { push: false, i })
    },
    onThumbClick (index) {
      this.photoIndex = index
    },
    handleHide () {
      this.photoIndex = null
    },
    loadAvatar (avatar, size = 'xs') {
      return avatar ? imageURL(avatar, size) : require('@/assets/img/user.png')
    },
    async uploadPhoto (image) {
      const arr = image.dataUrl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const res = await fetch(image.dataUrl)
      const blob = await res.blob()
      const file = new File([blob], 'image.' + image.format, { type: mime })
      console.log(file)
      const doc = await documentUploadService.documentUpload(file)
      console.log(doc)
      if (doc.data['0']) {
        return doc.data[0].original.split('original/')[1]
      }
      this.setSnackbar({ show: true, text: 'Error subiendo imagen', color: 'red' })
      return null
    }
  }
}
</script>

<style scoped>
.box-shadow-none {
  box-shadow: none !important;
}
.content-img {
  position: relative;
  height: 81px;
  width: 81px;
  border: 1px solid #ada5a5
}
.content-img img {
  object-fit: contain;
}
.btn-img {
  position: absolute;
  bottom: 5px;
  right: 0;
}
</style>
