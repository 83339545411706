<template>
  <v-dialog v-model="dialogModal" persistent scrollable max-width="600px">
    <v-card>
      <v-toolbar class="box-shadow-none">
        <v-toolbar-title>Tarea</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeTask">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- style="max-height: 495px;" -->
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="formTaskModal.taskOption"
                :items="tasksOptions.data"
                :search-input.sync="tasksOptions.search"
                :loading="tasksOptions.isLoading"
                :label="`Tarea *`"
                persistent-hint
                required
                item-text="name"
                item-value="_id"
                :error-messages="nameErrors('taskOption', { required: true })"
                :disabled="taskType === 'service'"
                return-object
                clearable
                class="pa-0 ma-0"
              >
                <template v-slot:append-item>
                  <div v-show="tasksOptions.hasNextPage" v-intersect="onIntersectTasks" class="pa-4 teal--text">
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="formTaskModal.place"
                :items="places.data"
                :search-input.sync="places.search"
                :loading="places.isLoading"
                :label="`Lugar *`"
                persistent-hint
                required
                item-text="name"
                item-value="_id"
                :error-messages="nameErrors('place', { required: true })"
                :disabled="taskType === 'service'"
                return-object
                clearable
                class="pa-0 ma-0"
              >
                <template v-slot:append-item>
                  <div v-show="places.hasNextPage" v-intersect="onIntersectPlaces" class="pa-4 teal--text">
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <date-picker
                v-model="formTaskModal.dateStart"
                format="hh:mm a"
                value-type="date"
                type="time"
                placeholder="Inicio *"
                class="w-100"
                required
                :open.sync="dtpStartOpen"
                :input-class="`mx-input ${$v.formTaskModal.dateStart.$error ? 'error-field': ''}`"
                :disabled="taskType === 'service'"
              >
                <template v-slot:footer>
                  <button class="v-btn v-size--default btn-ok primary" @click="dtpStartOpen = false">Ok</button>
                </template>
              </date-picker>
              <div class="v-text-field__details mt-1">
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper" v-show="$v.formTaskModal.dateEnd.$error && !$v.formTaskModal.dateStart.required">
                    <div class="v-messages__message">Campo es requiredo.</div>
                  </div>
                  <div class="v-messages__wrapper" v-show="!$v.formTaskModal.dateStart.notSame">
                    <div class="v-messages__message">Hora de inicio debe ser menor a la de Fin</div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <date-picker
                v-model="formTaskModal.dateEnd"
                format="hh:mm a"
                value-type="date"
                type="time"
                placeholder="Finalización *"
                class="w-100"
                required
                :open.sync="dtpEndOpen"
                :input-class="`mx-input ${$v.formTaskModal.dateEnd.$error ? 'error-field': ''}`"
                :disabled="taskType === 'service'"
              >
                <template v-slot:footer>
                  <button class="v-btn v-size--default btn-ok primary" @click="dtpEndOpen = false">Ok</button>
                </template>
              </date-picker>
              <div class="v-text-field__details mt-1">
                <div class="v-messages theme--light error--text" role="alert" v-show="$v.formTaskModal.dateEnd.$error">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">Campo es requiredo.</div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="formTaskModal.priority"
                :items="priorities"
                required
                :error-messages="nameErrors('priority', { required: true })"
                class="pa-0 ma-0"
              >
                <template v-slot:item="{ item }">
                  <span class="custom-option" :style="`background-color: ${item.color}`"></span>
                  {{ item.title }}
                </template>
                <template v-slot:selection="{ item }">
                  <span class="custom-option" :style="`background-color: ${item.color}`"></span>
                  {{ item.title }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select v-model="formTaskModal.status" :items="status" required class="pa-0 ma-0" disabled>
                <template v-slot:item="{ item }">
                  <span class="custom-option" :style="`background-color: ${item.color}`"></span>
                  {{ item.title }}
                </template>
                <template v-slot:selection="{ item }">
                  <span class="custom-option" :style="`background-color: ${item.color}`"></span>
                  {{ item.title }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea
                class="pa-0 ma-0"
                label="Descripción"
                v-model="formTaskModal.description"
                rows="2"
                auto-grow
                :error-messages="nameErrors('description', { maxLength: true, length: 255 })"
              ></v-textarea>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-switch
                class="ma-0 pa-0"
                v-model="isChecklist"
                label="Sub Tareas"
                hide-details
              ></v-switch>
              <v-expand-transition>
                <Checklist v-show="isChecklist" :list="formTaskModal.checklist" @addCheck="addCheck" @removeCheck="removeCheck"></Checklist>
              </v-expand-transition>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <small>*indica los campos requeridos</small>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="submitTask">
          {{ btnName }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import apiEmployeeTasksOptions from '@/api/employeeTasksOptions'
import apiPlaces from '@/api/places'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import DatePicker from 'vue2-datepicker'
import Checklist from '@/components/Shared/task/CheckListTask.vue'
export default {
  mixins: [validationMixin],
  components: { DatePicker, Checklist },
  props: ['dialog', 'formTask', 'area', 'btnName', 'isClear', 'employeeId', 'taskType'],
  data () {
    return {
      formTaskModal: {
        taskOption: null,
        place: null,
        employee: this.employeeId,
        priority: 'normal',
        dateStart: null,
        dateEnd: null,
        checklist: [],
        description: '',
        status: 'pending'
      },
      tasksOptions: {
        data: [],
        page: 1,
        hasNextPage: true,
        search: null,
        isLoading: false
      },
      places: {
        data: [],
        page: 1,
        hasNextPage: true,
        search: null,
        isLoading: false
      },
      priorities: [
        { color: '#ed4c5c', title: 'Muy alto', value: 'very-high' },
        { color: '#ffce31', title: 'Alto', value: 'high' },
        { color: '#00a790', title: 'Normal', value: 'normal' },
        { color: '#43c4e3', title: 'Bajo', value: 'low' },
        { color: '#b01aff', title: 'Muy bajo', value: 'very-low' }
      ],
      status: [
        { color: '#fb8f00', title: 'Abierto', value: 'open' },
        { color: '#ffce31', title: 'Pendiente', value: 'pending' },
        { color: '#ed4c5c', title: 'Cancelado', value: 'cancel' },
        { color: '#43c4e3', title: 'En proceso', value: 'process' },
        { color: '#00a790', title: 'Completado', value: 'completed' },
        { color: '#b01aff', title: 'Revisado', value: 'reviewed' }
      ],
      isChecklist: false,
      dtpStartOpen: false,
      dtpEndOpen: false
    }
  },
  validations: {
    formTaskModal: {
      taskOption: { required },
      place: { required },
      priority: { required },
      dateStart: {
        required,
        notSame: (val, parentVm) => {
          if (parentVm.dateEnd && parentVm.dateStart) {
            return parentVm.dateEnd > parentVm.dateStart
          }
          return true
        }
      },
      dateEnd: { required },
      description: { maxLength: maxLength(255) }
    }
  },
  computed: {
    dialogModal: {
      get () {
        return this.dialog
      },
      set (value) {
        if (!value) this.$emit('dialogTaskClose')
      }
    }
  },
  methods: {
    closeTask () {
      this.clearTask()
      this.$emit('closeTask')
    },
    clearTask () {
      this.$v.formTaskModal.$reset()
      this.formTaskModal = {
        taskOption: null,
        place: null,
        employee: this.formTaskModal.employee,
        priority: 'normal',
        dateStart: null,
        dateEnd: null,
        checklist: [],
        description: '',
        status: 'pending'
      }
      this.isChecklist = false
    },
    async loadTasksOptions (isScrolling = false, selectName = '') {
      const query = selectName === ''
        ? {
            deleted: false,
            page: this.tasksOptions.page,
            per_page: 10,
            search: this.tasksOptions.search || ''
          }
        : { page: 1, per_page: 10, search: selectName }
      const { data } = await apiEmployeeTasksOptions.list(this.area, query)
      this.tasksOptions.data = isScrolling
        ? this.tasksOptions.data.concat(data.data)
        : data.data
      this.tasksOptions.hasNextPage = data.current_page < data.last_page
    },
    async loadPlaces (isScrolling = false, selectName = '') {
      const query = selectName === ''
        ? {
            page: this.places.page,
            per_page: 10,
            search: this.places.search || '',
            sort: '{ "field":"name", "sortField":"name", "direction": "desc" }',
            withOutVirtuals: true
          }
        : { page: 1, per_page: 10, search: selectName, withOutVirtuals: true }
      const data = await apiPlaces.list(query)
      this.places.data = isScrolling
        ? this.places.data.concat(data.data.data)
        : data.data.data
      this.places.hasNextPage = data.data.links.pagination.current_page < data.data.links.pagination.last_page
    },
    onIntersectTasks (isIntersecting) {
      if (isIntersecting && this.tasksOptions.hasNextPage) {
        this.tasksOptions.page += 1
        this.loadTasksOptions(true)
      }
    },
    onIntersectPlaces (isIntersecting) {
      if (isIntersecting && this.places.hasNextPage) {
        this.places.page += 1
        this.loadPlaces(true)
      }
    },
    nameErrors (name, nameErrors) {
      const { required = false, maxLength = false, length = 0 } = nameErrors
      const errors = []
      if (!this.$v.formTaskModal[name].$dirty) return errors
      if (required) !this.$v.formTaskModal[name].required && errors.push('Campo es requiredo.')
      if (maxLength) !this.$v.formTaskModal[name].maxLength && errors.push(`Debe tener como máximo ${length} caracteres`)
      return errors
    },
    addCheck (val) {
      // this.$emit('addCheck', val)
      this.formTaskModal.checklist.push(val)
    },
    removeCheck (i) {
      this.formTaskModal.checklist.splice(i, 1)
      // this.$emit('removeCheck', val)
    },
    submitTask () {
      this.$v.formTaskModal.$touch()
      if (!this.$v.formTaskModal.$invalid) {
        console.log('this.places.page += 1', this.formTaskModal)
        this.$emit('submitTask', this.formTaskModal)
      }
    }
  },
  watch: {
    dialogModal (val) {
      console.log('valvalval', val)
      if (val) {
        if (this.btnName === 'Editar') {
          this.formTaskModal = { ...this.formTask }
          this.formTaskModal.checklist = this.formTaskModal.checklist.slice()
          console.log('formTaskModal', this.formTaskModal)
          console.log('area', this.area)
          if (this.formTaskModal.checklist.length > 0) this.isChecklist = true
          this.loadTasksOptions(false, this.formTaskModal.taskOption.name)
          this.loadPlaces(false, this.formTaskModal.place.name)
        } else {
          this.loadTasksOptions()
          this.places.page = 1
          this.loadPlaces()
        }
      }
    },
    async 'tasksOptions.search' (val) {
      this.tasksOptions.isLoading = true
      this.tasksOptions.page = 1
      await this.loadTasksOptions()
      this.tasksOptions.isLoading = false
    },
    async 'places.search' (val) {
      this.places.isLoading = true
      this.places.page = 1
      await this.loadPlaces()
      this.places.isLoading = false
    },
    isClear (val) {
      if (val) {
        this.clearTask()
        this.$emit('isClearModalFalse')
      }
    },
    'formTaskModal.taskOption' (val) {
      console.log(this.formTask, 'this.formTask')
      if (!this.formTask.taskOption) {
        console.log(val)
        if (val?.checklist?.length > 0) {
          this.isChecklist = true
          this.formTaskModal.checklist = val.checklist.map(c => ({ done: false, text: c }))
        } else {
          this.isChecklist = false
          this.formTaskModal.checklist = []
        }
      }
    }
  }
}
</script>

<style scoped>
.box-shadow-none {
  box-shadow: none !important;
}
.fit-content {
  max-width: 174px !important;
}
.custom-option {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}
.btn-ok {
  background-color: #a88e68 !important;
  border-color: #a88e68 !important;
}
</style>
