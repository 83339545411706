import jwt from '@/services/jwtService'
import apiEmployee from '@/api/employee'
export default async (to, from, next) => {
  if (to.query.token) {
    const user = jwt.decode(to.query.token)
    if (to.query.token) {
      const employee = await apiEmployee.detailFilter(user._id, { select: 'avatar,email,name,permissions,placeBusiness,rol', populate: 'permissions:value rol:name' })
      localStorage.setItem('user', JSON.stringify(employee.data.data))
    }
    let path = to.path
    if (to.query.area) {
      path += '?area=' + to.query.area
      if (to.query.place) path += '&place=' + to.query.place
    }
    next(path)
  } else {
    next()
  }
}
