<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" :max-width="maxWidth">
      <v-card>
        <v-toolbar class="box-shadow-none">
          <v-toolbar-title>Cambiar status</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col v-if="!statusTransition.includes('cancel') && !statusTransition.includes('completed')">
                  <v-checkbox
                    id="chkCancel"
                    v-model="form.status"
                    :label="status[0].title"
                    :color="status[0].color"
                    :value="status[0].value"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <template v-if="!statusTransition.includes('reviewed')">
                  <v-col v-show="statusTransition.includes('completed')">
                    <v-checkbox
                      id="chkReviewed"
                      v-model="form.status"
                      :label="status[3].title"
                      :color="status[3].color"
                      :value="status[3].value"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col v-show="statusTransition.includes('process') && !statusTransition.includes('completed')">
                    <template v-if="isService">
                      <div>solo se pude completar desde el servicio</div>
                    </template>
                    <template v-else>
                      <v-checkbox
                        id="chkCompleted"
                        v-model="form.status"
                        :label="status[2].title"
                        :color="status[2].color"
                        :value="status[2].value"
                        hide-details
                      ></v-checkbox>
                    </template>
                  </v-col>
                  <v-col v-show="(statusTransition.length === 0 || statusTransition.includes('pending') && !statusTransition.includes('process'))">
                    <v-checkbox
                      id="chkProcess"
                      v-model="form.status"
                      :label="status[1].title"
                      :color="status[1].color"
                      :value="status[1].value"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </template>
              </v-row>
              <div v-if="nameErrors('status', { required: true }) != 0" class="v-messages theme--light error--text mt-2" role="alert">
                <div class="v-messages__wrapper"><div class="v-messages__message">Campo es requiredo.</div></div>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="d-flex justify-space-between mb-1">
                <h3>Inventario</h3>
                <v-btn color="primary" x-small v-if="statusTransition.includes('process') && !statusTransition.includes('completed')" @click="dialogItems = true">
                  Editar
                </v-btn>
              </div>
              <ul v-if="items.length > 0">
                <li v-for="(item, index) in items" :key="index">
                  <span class="d-flex justify-space-between">
                    <span>{{ item.item.name }}</span>
                    <span>{{ item.qty > 0 ? item.qty : 0 }} {{ item.item.weightUnit }}</span>
                  </span>
                </li>
              </ul>
              <p v-else>No disponible</p>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Nota"
                v-model="form.note"
                rows="2"
                auto-grow
                :counter="255"
                max-lenght="255"
                :error-messages="nameErrors('note', { maxLength: true, length: 255 })"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" @click="submit">
            Cambiar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogItems" max-width="500px">
      <v-card>
        <v-toolbar class="box-shadow-none">
          <v-toolbar-title>Inventario utilizado</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogItems = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-item v-for="(item, index) in items" :key="index">
              <v-list-item-content>
                <v-text-field type="number" v-model="item.qty" :label="item.item.name" :suffix="item.item.weightUnit"></v-text-field>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNoitems" max-width="500px">
      <v-card>
        <v-toolbar class="box-shadow-none">
          <v-toolbar-title>Inventario no disponible</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogNoitems = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-item v-for="(item, index) in noItems" :key="index">
              <v-list-item-content>
                {{ item.name }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="completed">
            Completar de todos modos
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import apiInventoryActive from '@/api/inventoryActive'
export default {
  name: 'StatusTask',
  props: ['visible', 'maxWidth', 'statusTransition', 'items', 'place', 'taskId', 'business', 'isService'],
  computed: {
    dialog: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) this.$emit('closeModalStatusTask')
      }
    }
  },
  watch: {
    dialog (val) {
      if (!val) this.clear()
    }
  },
  mixins: [validationMixin],
  data: () => ({
    form: {
      status: '',
      note: ''
    },
    status: [
      { color: '#ed4c5c', title: 'Cancelado', value: 'cancel' },
      { color: '#43c4e3', title: 'En proceso', value: 'process' },
      { color: '#00a790', title: 'Completado', value: 'completed' },
      { color: '#b01aff', title: 'Revisado', value: 'reviewed' }
    ],
    dialogItems: false,
    dialogNoitems: false,
    noItems: [],
    usedItems: []
  }),
  validations: {
    form: {
      status: { required },
      note: { maxLength: maxLength(255) }
    }
  },
  methods: {
    async submit () {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        if (this.form.status === 'completed' && this.items.length > 0) {
          console.log(this.items, this.form)
          const { data: { data } } = await apiInventoryActive.list({ page: 1, per_page: 100, sort: 'asc', searchPlace: this.business })
          console.log(data, 'apiInventoryActive')
          this.usedItems = []
          this.noItems = []
          this.items.forEach(element => {
            const find = data.find(d => element.item._id === d.item._id && d.qty >= element.qty)
            if (find) this.usedItems.push({ _id: find._id, qty: element.qty, item: element.item._id, reference: this.taskId })
            else this.noItems.push({ item: element.item._id, name: element.item.name })
          })
          console.log('usedItems', this.usedItems)
          console.log('noItems', this.noItems)
          if (this.noItems.length > 0) {
            this.dialogNoitems = true
          } else {
            await apiInventoryActive.removeBulk(this.usedItems)
            this.$emit('changeStatus', { ...this.form })
            this.dialog = false
          }
        } else {
          this.$emit('changeStatus', { ...this.form })
          this.dialog = false
        }
      }
    },
    completed () {
      if (this.usedItems.length) apiInventoryActive.removeBulk(this.usedItems)
      this.dialogNoitems = false
      this.$emit('changeStatus', { ...this.form })
      this.dialog = false
    },
    nameErrors (name, nameErrors) {
      const { required = false, maxLength = false, length = 0 } = nameErrors
      const errors = []
      if (!this.$v.form[name].$dirty) return errors
      if (required) !this.$v.form[name].required && errors.push('Campo es requiredo.')
      if (maxLength) !this.$v.form[name].maxLength && errors.push(`Debe tener como máximo ${length} caracteres`)
      return errors
    },
    clear () {
      this.$v.form.$reset()
      this.form.note = ''
      this.form.status = ''
    }
  }
}
</script>

<style scoped>
.custom-option {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}
.box-shadow-none {
  box-shadow: none !important;
}
</style>
