import axios from '@/services/axiosService'

export default {
  async list (params = {}) {
    return axios().get('/employee/tasks/list-paginate', { params })
  },
  async listPaginate (params = {}) {
    return axios({ permission: true }).get('/employee/list-paginate', { params })
  },
  async detail (id) {
    return axios().get(`/employee/detail/${id}`)
  },
  async detailFilter (id, params = {}) {
    return axios().get(`/employee/detail/filter/${id}`, { params })
  }
}
