<template>
  <v-container class="pa-0">
    <v-list three-line class="pa-0">
      <template v-for="(item, index) in items">
        <v-divider
          v-if="item.divider"
          :key="index"
          :inset="item.inset"
        ></v-divider>

        <v-list-item v-else :key="item.title" class="pa-0">
          <v-list-item-avatar>
            <v-img :src="loadAvatar(item.employee?.avatar)"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <div>
              <strong>{{ item.employee?.name }} {{ item.employee?.lastName }} </strong>
              <small class="mr-2">cambió el status a:</small>
              <v-chip outlined :color="status.find(x => x.value == item.status).color" x-small class="mr-2">
                {{ status.find(x => x.value == item.status).title }}
              </v-chip>

              <small class="text--primary float-end mt-1 mt-md-0">
                {{ dateFormat(item.date) }}
              </small>
            </div>
            <v-list-item-subtitle>
              <small>{{ item.note }}</small>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-container>
</template>

<script>
import { imageURL } from '@/utils'
import { format } from 'date-fns'
export default {
  name: 'TaskTransaction',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    items () {
      const array = []
      if (this.list.length > 0) {
        this.list.forEach(element => {
          array.push(element)
          array.push({ divider: true, inset: true })
        })
        array.pop()
      }
      return array
    }
  },
  data: () => ({
    status: [
      { color: '#fb8f00', title: 'Abierto', value: 'open' },
      { color: '#ffce31', title: 'Pendiente', value: 'pending' },
      { color: '#ed4c5c', title: 'Cancelado', value: 'cancel' },
      { color: '#43c4e3', title: 'En proceso', value: 'process' },
      { color: '#00a790', title: 'Completado', value: 'completed' },
      { color: '#b01aff', title: 'Revisado', value: 'reviewed' }
    ]
  }),
  methods: {
    loadAvatar (avatar) {
      return avatar ? imageURL(avatar, 'xs') : require('@/assets/img/user.png')
    },
    dateFormat (val) {
      return format(new Date(val), 'dd-MM-yyyy hh:mm a')
    }
  }
}
</script>
