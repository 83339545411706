<template>
  <Schedule :employeeId="user._id" :hasName="false" :prevRoute="prevRoute" />
</template>

<script>
// import HelloWorld from '@/components/HelloWorld.vue'
import Schedule from '@/components/Shared/task/ScheduleTask.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'HomeView',
  components: { Schedule },
  data: () => ({
    user: JSON.parse(localStorage.getItem('user')),
    prevRoute: {}
  }),
  beforeRouteEnter (to, from, next) {
    next(vm => {
      console.log(to, from)
      if (from.params.place && from.params.task) vm.prevRoute = from
      else vm.prevRoute = null
      console.log('vm.prevRoute', vm.prevRoute)
    })
  },
  computed: {
    ...mapGetters(['idQR'])
  },
  created () {
  }
}
</script>
