import { Camera, CameraResultType } from '@capacitor/camera'

const takePicture = async () => {
  const image = await Camera.getPhoto({
    quality: 50,
    allowEditing: false,
    resultType: CameraResultType.DataUrl,
    promptLabelHeader: 'Foto',
    promptLabelCancel: 'Cacelar',
    promptLabelPhoto: 'Galería',
    promptLabelPicture: 'Tomar la foto'
  })
  console.log(image)
  return image
}

export default { takePicture }
