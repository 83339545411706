import { io } from 'socket.io-client'
import { apiSocketIO } from '../constants/config'
import vuex from '../store'
class SocketioService {
  socket = null
  // constructor () {}

  setupSocketConnection (user) {
    this.socket = io(apiSocketIO, {
      query: { user, room: 'housekeeping' }
    })
    console.log(this.socket, '============', apiSocketIO)
    this.socket.on('scanner_door_opened', (data) => {
      console.log('scanner_door_opened', data)
      vuex.commit('setReponseScanner', { ...data, socket: true })
    })
  }

  disconnect () {
    if (this.socket) {
      this.socket.disconnect()
    }
  }

  getSocket () {
    return this.socket
  }
}

export default new SocketioService()
