import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'
import vuex from '../store'

const addListeners = async () => {
  // On success, we should be able to receive notifications
  await PushNotifications.addListener('registration', token => {
    console.info('Registration token: ', token.value)
    vuex.commit('setNotificationToken', token.value)
  })
  // Some issue with our setup and push will not work
  await PushNotifications.addListener('registrationError', err => {
    console.error('Registration error: ', err.error)
  })
  // Show us the notification payload if the app is open on our device
  await PushNotifications.addListener('pushNotificationReceived', notification => {
    console.log('Push notification received: ', notification)
    // PushNotifications.removeAllDeliveredNotifications()
    console.log('nuevo')
  })
  // Method called when tapping on a notification"^4.1.2"
  await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
    console.log('Push notification action performed', notification.actionId, notification.notification.data)
    if (notification.notification.data.date) {
      vuex.commit('setTaskDate', notification.notification.data.date)
      vuex.commit('setPushNotificationData', notification.notification.data)
    }
    if (notification.notification.data.service) {
      console.log('hola')
    }
  })
}

const registerNotifications = async () => {
  if (Capacitor.getPlatform() !== 'web') {
    let permStatus = await PushNotifications.checkPermissions()
    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions()
    }
    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!')
    }
    await PushNotifications.register()
    await addListeners()
  }
}

const getDeliveredNotifications = async () => {
  const notificationList = await PushNotifications.getDeliveredNotifications()
  console.log('delivered notifications', notificationList)
}

export default { registerNotifications, getDeliveredNotifications }
