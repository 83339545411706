import Vue from 'vue'
import Vuex from 'vuex'
import notification from './modules/notification'
import review from './modules/review-inventory'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: null,
    snackbar: {
      show: false,
      text: '',
      color: ''
    },
    idQR: '',
    reponseScanner: null
  },
  getters: {
    drawer: state => state.drawer,
    snackbar: state => state.snackbar,
    idQR: state => state.idQR,
    reponseScanner: state => state.reponseScanner
  },
  mutations: {
    setDrawer (state, payload) {
      state.drawer = payload
    },
    setSnackbar (state, payload) {
      state.snackbar = payload
    },
    closeSnackbar (state) {
      state.snackbar.show = false
    },
    setIdQR (state, payload) {
      state.idQR = payload
    },
    setReponseScanner (state, payload) {
      state.reponseScanner = payload
    }
  },
  actions: {
  },
  modules: {
    notification,
    review
  }
})
