<template>
  <v-container class="pa-3" fluid>
    <Breadcrumbs v-if="windowWidth > 600" title="Jornadas"></Breadcrumbs>
    <v-row class="px-4">
      <v-col cols="12" md="4">
        <v-row>
          <v-col class="px-0 pb-0" v-if="windowWidth < 961">
            <div v-if="hasName" class="d-flex align-center">
              <v-avatar size="56">
                <img alt="user" :src="this.loadAvatar(dataEmployee.avatar)">
              </v-avatar>
              <p class="ml-4 text-h6 text--secondary mb-0 f-12">{{ dataEmployee.name }} {{ dataEmployee.lastName }}</p>
            </div>
          </v-col>
          <v-col cols="12">
            <h2 class="text--secondary text-center">{{ months[startWeek.getMonth()] }} {{ endMonth }}</h2>
            <div class="align-center d-flex justify-center">
              <v-btn icon @click="backWeek()">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <h3 class="text--secondary mx-2">{{ formatDay(startWeek.getDate()) }} a {{ formatDay(endWeek.getDate()) }}</h3>
              <v-btn icon @click="advanceWeek()">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" v-for="item in workdays" :key="item.day">
            <v-hover>
              <v-card
                outlined
                :class="['card-workdays mx-auto', { 'bg-select': item.id == selectWorday }]"
                max-width="344"
                @click="selectDay(item)"
                >
                <v-card-text class="align-center d d-flex justify-space-between v-card__text">
                  <h3 class="primary--text d-inline-block">
                    {{ item.date }} - {{ item.day }}
                  </h3>
                  <h4 class="text--secondary d-inline-block text-body-2">
                    <template v-if="windowWidth > 960">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="text-small">
                            <v-icon color="success" style="font-size: larger">mdi-check-circle-outline</v-icon>
                            {{ item.compled }} de {{ item.total }}
                          </span>
                        </template>
                        <span>Tareas completadas</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="text-small">
                            <v-icon color="light-blue lighten-1" style="font-size: larger" class="ml-2">mdi-eye-check-outline</v-icon>
                            {{ item.reviewed }} de {{ item.total }}
                          </span>
                        </template>
                        <span>Tareas Revisadas</span>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      <span class="text-small">
                        <v-icon color="success" style="font-size: larger">mdi-check-circle-outline</v-icon>
                        {{ item.compled }} de {{ item.total }}
                      </span>
                      <span class="text-small">
                        <v-icon color="light-blue lighten-1" style="font-size: larger" class="ml-2">mdi-eye-check-outline</v-icon>
                        {{ item.reviewed }} de {{ item.total }}
                      </span>
                    </template>
                  </h4>
                </v-card-text>
              </v-card>
            </v-hover>
            <v-spacer />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8" v-if="windowWidth > 960">
        <TasksComponent
          :employeeId="employeeId"
          :hasName="hasName"
          :tasks="tasks"
          :workdays="workdays"
          :selectWorday="selectWorday"
          :isClear="clearTask"
          :dataEmployee="dataEmployee"
          :dateSelected="{ startWeek, endWeek }"
          :isDateGreater="isDateGreater"
          :windowWidth="windowWidth"
          @openTransaction="openTransaction"
          @openChecklist="openChecklist"
          @changeStatus="changeStatus"
          @addTask="addTask"
          @isClearFalse="clearTask=false"
          @updateTask="updateTask"
          @deletedItem="deletedItem"
        ></TasksComponent>
      </v-col>
    </v-row>
    <div v-if="windowWidth < 961">
      <v-dialog v-model="dialogSchedule" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialogSchedule = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Tareas</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <div class="pa-6">
            <TasksComponent
              :employeeId="employeeId"
              :hasName="hasName"
              :tasks="tasks"
              :workdays="workdays"
              :selectWorday="selectWorday"
              :isClear="clearTask"
              :dataEmployee="dataEmployee"
              :dateSelected="{ startWeek, endWeek }"
              :isDateGreater="isDateGreater"
              :windowWidth="windowWidth"
              @openTransaction="openTransaction"
              @openChecklist="openChecklist"
              @changeStatus="changeStatus"
              @addTask="addTask"
              @isClearFalse="clearTask=false"
              @updateTask="updateTask"
              @deletedItem="deletedItem"
            ></TasksComponent>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/containers/Breadcrumbs.vue'
import { endOfWeek, startOfWeek, addWeeks, subWeeks, addDays, format, differenceInWeeks } from 'date-fns'
import 'vue2-datepicker/index.css'
import apiEmployeeTasks from '@/api/employeeTasks'
import apiEmployee from '@/api/employee'
import { imageURL } from '@/utils'
import TasksComponent from '@/components/Shared/task/TasksComponent.vue'
import { mapGetters, mapMutations } from 'vuex'
import socketioService from '@/services/socketioService'
export default {
  components: { Breadcrumbs, TasksComponent },
  props: ['employeeId', 'hasName', 'prevRoute'],
  data: () => ({
    workdays: [
      { id: 1, day: 'Lunes', year: '', month: '', date: '01', compled: 0, reviewed: 0, total: 0 },
      { id: 2, day: 'Martes', year: '', month: '', date: '02', compled: 0, reviewed: 0, total: 0 },
      { id: 3, day: 'Miercoles', year: '', month: '', date: '03', compled: 0, reviewed: 0, total: 0 },
      { id: 4, day: 'Jueves', year: '', month: '', date: '04', compled: 0, reviewed: 0, total: 0 },
      { id: 5, day: 'Viernes', year: '', month: '', date: '05', compled: 0, reviewed: 0, total: 0 },
      { id: 6, day: 'Sábado', year: '', month: '', date: '06', compled: 0, reviewed: 0, total: 0 },
      { id: 0, day: 'Domingo', year: '', month: '', date: '07', compled: 0, reviewed: 0, total: 0 }
    ],
    selectWorday: new Date().getDay(),
    select: 10,
    pages: [10, 15, 20, 50],
    page: 1,
    windowWidth: window.innerWidth,
    startWeek: startOfWeek(new Date(), { weekStartsOn: 1 }),
    endWeek: endOfWeek(new Date(), { weekStartsOn: 1 }),
    months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    dialog: false,
    tasksWeek: [],
    tasks: [],
    isChecklist: false,
    clearTask: false,
    dialogSchedule: false,
    dataEmployee: {},
    isDateGreater: false
  }),
  mounted () {
    window.addEventListener('resize', this.handleResize)
  },
  created () {
    this.loadEmployee()
  },
  computed: {
    ...mapGetters(['taskDate']),
    endMonth () {
      return this.startWeek.getMonth() === this.endWeek.getMonth() ? '' : `- ${this.months[this.endWeek.getMonth()]}`
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    handleResize () {
      this.windowWidth = window.innerWidth
    },
    async loadEmployee () {
      const data = await apiEmployee.detail(this.employeeId)
      this.dataEmployee = data.data.data
    },
    async loadTasks () {
      const query = {
        startDate: format(this.startWeek, 'yyyy-MM-dd'),
        endDate: format(this.endWeek, 'yyyy-MM-dd')
      }
      const data = await apiEmployeeTasks.listPerWeek(this.employeeId, query)
      console.log('============================================')
      console.log(data)
      console.log('============================================')
      this.tasksWeek = data.data.data
      this.tasksWeek.forEach(element => {
        element.tasks.forEach(t => {
          t.isChecklist = false
          t.isTransaction = false
        })
      })
      this.updateDays()
      this.tasksbyDay()
    },
    async updateTask (data) {
      const task = this.tasks.find(t => t._id === data.taskId)
      for (const key in data.formTask) {
        if (Object.hasOwnProperty.call(data.formTask, key)) {
          task[key] = data.formTask[key]
        }
      }
      task.isChecklist = false
      this.tasks.sort((a, b) => Number(a.dateStart) - Number(b.dateStart))
      const w = this.workdays.find(w => w.id === this.selectWorday)
      const week = this.tasksWeek.find(t => t._id.substr(-2) === w.date)
      week.tasks = this.tasks
      this.clearTask = true
    },
    backWeek (number = 1) {
      this.startWeek = subWeeks(this.startWeek, number)
      this.endWeek = subWeeks(this.endWeek, number)
      this.loadTasks()
    },
    advanceWeek (number = 1) {
      this.startWeek = addWeeks(this.startWeek, number)
      this.endWeek = addWeeks(this.endWeek, number)
      this.loadTasks()
    },
    updateDays () {
      let date = this.startWeek
      this.workdays.forEach(element => {
        element.date = String(this.formatDay(date.getDate()))
        element.year = date.getFullYear()
        element.month = date.getMonth()
        date = addDays(date, 1)
        const task = this.tasksWeek.find(t => t._id.substr(-2) === element.date)
        if (task) {
          element.total = task.tasks.length
          element.compled = task.tasks.filter(t => t.status === 'completed' || t.status === 'reviewed').length
          element.reviewed = task.tasks.filter(t => t.status === 'reviewed').length
        } else {
          element.total = 0
          element.compled = 0
          element.reviewed = 0
        }
      })
    },
    formatDay (day) {
      return day < 10 ? `0${day}` : day
    },
    async addTask (data) {
      const w = this.workdays.find(w => w.id === this.selectWorday)
      w.total++
      w.compled = data.formTask.status === 'completed' ? w.compled + 1 : w.compled
      const index = this.tasks.findIndex(t => t.dateStart > data.formTask.dateStart)
      const val = {
        _id: data.dataTask.employeeTask._id,
        isChecklist: false,
        isTransaction: false,
        transaction: [data.dataTask.employeeTaskTransaction],
        ...data.formTask
      }
      if (index === -1) {
        this.tasks.push(val)
      } else {
        this.tasks.splice(index, 0, val)
      }
      const indexTasksWeek = this.tasksWeek.findIndex(t => t._id.substr(-2) === w.date)
      if (indexTasksWeek !== -1) {
        this.tasksWeek[indexTasksWeek].tasks = this.tasks
      } else {
        this.tasksWeek.push({
          _id: `${format(new Date(), 'yyyy-MM')}-${w.date}`,
          tasks: this.tasks
        })
      }
      this.clearTask = true
    },
    selectDay (data) {
      if (this.windowWidth < 961) {
        this.dialogSchedule = true
        this.loadEmployee()
      }
      this.selectWorday = data.id
      this.tasksbyDay()
    },
    loadAvatar (avatar) {
      return avatar ? imageURL(avatar, 'xs') : require('@/assets/img/user.png')
    },
    tasksbyDay () {
      const day = this.workdays.find(w => w.id === this.selectWorday)
      let month = day.month + 1
      month = month < 10 ? `0${month}` : month
      const date1 = new Date(`${day.year}-${month}-${day.date} 23:59`)
      this.isDateGreater = date1 > new Date()
      const task = this.tasksWeek.find(t => t._id.substr(-2) === day.date)
      this.tasks = []
      if (task) {
        task.tasks.forEach(t => {
          this.tasks.push({
            _id: t._id,
            taskOption: t.taskOption,
            place: t.place,
            employee: t.employee,
            priority: t.priority,
            dateStart: new Date(t.dateStart),
            dateEnd: new Date(t.dateEnd),
            description: t.description,
            isChecklist: t.isChecklist,
            isTransaction: t.isTransaction,
            checklist: t.checklist,
            transaction: t.transaction,
            photos: t.photos || [],
            type: t.type,
            status: t.status
          })
        })
      }
    },
    async deletedItem (taskIdDelete) {
      try {
        const data = await apiEmployeeTasks.delete(taskIdDelete)
        if (data.data.ok) {
          const w = this.workdays.find(w => w.id === this.selectWorday)
          w.total--
          const index = this.tasks.findIndex(t => t._id === taskIdDelete)
          w.compled = this.tasks[index].status === 'completed' ? w.compled - 1 : w.compled
          this.tasks.splice(index, 1)
          const indexTasksWeek = this.tasksWeek.findIndex(t => t._id.substr(-2) === w.date)
          this.tasksWeek[indexTasksWeek].tasks = this.tasks
          if (this.tasks[index].status === 'process') {
            console.log('================================================================', this.tasks[index])
            socketioService.getSocket().emit('delete_one_fingerprint_place', { user: this.tasks[index].employee, place: this.tasks[index].place._id })
          }
        }
      } catch (err) {
        const msg = err.response.data?.err === 'No Permission' ? 'Acceso no autorizado' : null
        this.setSnackbar({ show: true, text: msg || err.response.data.err || err.response.data.message, color: 'red' })
      }
    },
    openChecklist (index) {
      this.tasks[index].isTransaction = false
      this.tasks[index].isChecklist = !this.tasks[index].isChecklist
    },
    openTransaction (index) {
      this.tasks[index].isChecklist = false
      this.tasks[index].isTransaction = !this.tasks[index].isTransaction
    },
    async changeStatus (data) {
      const task = this.tasks.find(t => t._id === data.id)
      const w = this.workdays.find(w => w.id === this.selectWorday)
      if (task.status !== data.status) {
        if (task.status === 'completed') {
          if (data.status === 'reviewed') {
            w.reviewed++
          } else {
            w.compled--
          }
        } else if (task.status === 'reviewed') {
          if (data.status === 'completed') {
            w.reviewed--
          } else {
            w.compled--
            w.reviewed--
          }
        } else {
          if (data.status === 'completed') {
            w.compled++
          } else if (data.status === 'reviewed') {
            w.compled++
            w.reviewed++
          }
        }
      }
      task.status = data.status
      task.transaction.push(data.employeeTaskTransaction)
    },
    onEmployeeLoad (data) {
      this.dataEmployee = data
    }
  },
  watch: {
    async taskDate (val) {
      if (val) {
        this.startWeek = startOfWeek(new Date(val), { weekStartsOn: 1 })
        this.endWeek = endOfWeek(new Date(val), { weekStartsOn: 1 })
        await this.loadTasks()
        this.selectDay({ id: new Date(val).getDay() })
      }
    },
    prevRoute (val) {
      if (val) {
        const result = differenceInWeeks(this.startWeek, new Date(val.query.startWeek))
        this.selectWorday = Number(val.query.day)
        if (result > 0) {
          this.backWeek(result)
        } else if (result < 0) {
          this.advanceWeek(Math.abs(result))
        } else {
          this.loadTasks()
        }
      } else {
        this.loadTasks()
      }
    }
  }
}
</script>
<style scoped>
.row-per-page label {
  margin-top: 20px;
}
.row-per-page .select-pages {
  margin-left: 10px;
  max-width: 60px !important;
}
.custom-option {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}
.h-22 {
  height: 22px;
}
.min-width-70 {
  min-width: max-content;
}
.bg-select {
  background-color: #eee2d0;
}
.card-workdays {
  transition: .5s;
}
.card-workdays:hover {
  background-color: #dad8d8;
}
.text-small {
  font-size: small;
}
.f-12 {
  font-size: 1.2rem !important;
}
</style>
