import { render, staticRenderFns } from "./ModalDelete.vue?vue&type=template&id=8a30eff8&scoped=true"
import script from "./ModalDelete.vue?vue&type=script&lang=js"
export * from "./ModalDelete.vue?vue&type=script&lang=js"
import style0 from "./ModalDelete.vue?vue&type=style&index=0&id=8a30eff8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a30eff8",
  null
  
)

export default component.exports