import axios from '@/services/axiosService'

export default {
  async list (params) {
    return axios({ url: 'inventory' }).get('/active/byplace', { params })
  },
  async removeBulk (body) {
    return axios({ url: 'inventory' }).put('/active/remove/bulk', body)
  }
}
