import axios from 'axios'
import { apiFileUrl } from '../constants/config'

export default {
  /**
   * @description subir una imagen o pdf
   * @param {*} file
   */
  async documentUpload (file) {
    try {
      const formData = new FormData()
      const type = file.type === 'application/pdf' ? 'pdf' : 'image'
      formData.append(type, file, file.name)
      formData.append('name', type)
      const data = await axios.post(`${apiFileUrl}/${type}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      console.log('document', data)
      return data
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  /**
   * @description subir multiples imagenes
   * @param {*} files
  */
  async documentUploadMultiple (files) {
    try {
      const formData = new FormData()
      files.forEach(file => {
        formData.append('image', file)
      })
      const data = await axios.post(`${apiFileUrl}/image`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      console.log('document', data)
      return data
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  /**
   * @description eliminar una imagen o pdf
   * @param {*} name
   * @param {*} type - image pdf
   */
  async deleteDocument (name, type) {
    try {
      const data = await axios.delete(`${apiFileUrl}/${type}/${name}`)
      console.log('document', data)
      return data
    } catch (err) {
      console.log(err)
      return err.response
    }
  }
}
