export default {
  state: {
    reviewItems: [],
    selectedItem: {
      note: {
        msg: '',
        photos: []
      }
    },
    reviewItemsLastPage: false,
    review: false,
    pageReviewItems: 1
  },
  getters: {
    reviewItems: state => state.reviewItems,
    selectedItem: state => state.selectedItem,
    reviewItemsLastPage: state => state.reviewItemsLastPage,
    review: state => state.review,
    pageReviewItems: state => state.pageReviewItems
  },
  mutations: {
    setReviewItemsLastPage (state, payload) {
      state.reviewItemsLastPage = payload
    },
    setSelectedItem (state, payload) {
      console.log('payloadpayload', payload)
      state.selectedItem = payload
    },
    setReview (state, payload) {
      state.review = payload
    },
    incrementPageReviewItems (state) {
      state.pageReviewItems++
    },
    concatReviewItems (state, payload) {
      state.reviewItems = state.reviewItems.concat(payload)
    },
    clearReviewItems (state) {
      state.reviewItems = []
      state.reviewItemsLastPage = false
      state.review = false
      state.selectedItem = {
        note: {
          msg: '',
          photos: []
        }
      }
      state.pageReviewItems = 1
    }
  },
  actions: {
  }
}
