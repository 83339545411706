import axios from '@/services/axiosService'

export default {
  async list (params = {}) {
    return axios({ url: 'gestion' }).get('/place/table', { params })
  },
  async listRoom (params = {}) {
    return axios().get('/place/list/room', { params })
  },
  async updateCleaned (_id, cleaned) {
    return axios({ url: 'gestion' }).put('/room/cleaned', { _id, cleaned })
  }
}
