export const apiUrl = process.env.VUE_APP_API_URL
export const apiAuthUrl = process.env.VUE_APP_AUTH_URL
export const apiGestionUrl = process.env.VUE_APP_API_URL_GESTION
export const apiInventoryUrl = process.env.VUE_APP_API_URL_INVENTORY
export const apiFileUrl = process.env.VUE_APP_FILE_URL
export const imagesUrl = process.env.VUE_APP_URL_IMAGE
export const nameApp = process.env.VUE_APP_NAME_APP
export const permissionKey = process.env.VUE_APP_PERMISSION_KEY
export const apiSocketIO = process.env.VUE_APP_API_SOCKETIO
export const apiMaster = process.env.VUE_APP_API_MASTER
export const apiNotification = process.env.VUE_APP_API_NOTIFICATION

export const defaultLocale = 'en'
export const defaultDirection = 'ltr'
export const localeOptions = [
  { id: 'en', name: 'English LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English RTL', direction: 'rtl' }
]

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24'
  // role: UserRole.Admin
}

export const hours = [
  {
    hour2: '12:00 am',
    hour: '00:00'
  },
  {
    hour2: '12:30 am',
    hour: '00:30'
  },
  {
    hour2: '01:00 am',
    hour: '01:00'
  },
  {
    hour2: '01:30 am',
    hour: '01:30'
  },
  {
    hour2: '02:00 am',
    hour: '02:00'
  },
  {
    hour2: '02:30 am',
    hour: '02:30'
  },
  {
    hour2: '03:00 am',
    hour: '03:00'
  },
  {
    hour2: '03:30 am',
    hour: '03:30'
  },
  {
    hour2: '04:00 am',
    hour: '04:00'
  },
  {
    hour2: '04:30 am',
    hour: '04:30'
  },
  {
    hour2: '05:00 am',
    hour: '05:00'
  },
  {
    hour2: '05:30 am',
    hour: '05:30'
  },
  {
    hour2: '06:00 am',
    hour: '06:00'
  },
  {
    hour2: '06:30 am',
    hour: '06:30'
  },
  {
    hour2: '07:00 am',
    hour: '07:00'
  },
  {
    hour2: '07:30 am',
    hour: '07:30'
  },
  {
    hour2: '08:00 am',
    hour: '08:00'
  },
  {
    hour2: '08:30 am',
    hour: '08:30'
  },
  {
    hour2: '09:00 am',
    hour: '09:00'
  },
  {
    hour2: '09:30 am',
    hour: '09:30'
  },
  {
    hour2: '10:00 am',
    hour: '10:00'
  },
  {
    hour2: '10:30 am',
    hour: '10:30'
  },
  {
    hour2: '11:00 am',
    hour: '11:00'
  },
  {
    hour2: '11:30 am',
    hour: '11:30'
  },
  {
    hour2: '12:00 pm',
    hour: '12:00'
  },
  {
    hour2: '12:30 pm',
    hour: '12:30'
  },
  {
    hour2: '01:00 pm',
    hour: '13:00'
  },
  {
    hour2: '01:30 pm',
    hour: '13:30'
  },
  {
    hour2: '02:00 pm',
    hour: '14:00'
  },
  {
    hour2: '02:30 pm',
    hour: '14:30'
  },
  {
    hour2: '03:00 pm',
    hour: '15:00'
  },
  {
    hour2: '03:30 pm',
    hour: '15:30'
  },
  {
    hour2: '04:00 pm',
    hour: '16:00'
  },
  {
    hour2: '04:30 pm',
    hour: '16:30'
  },
  {
    hour2: '05:00 pm',
    hour: '17:00'
  },
  {
    hour2: '05:30 pm',
    hour: '17:30'
  },
  {
    hour2: '06:00 pm',
    hour: '18:00'
  },
  {
    hour2: '06:30 pm',
    hour: '18:30'
  },
  {
    hour2: '07:00 pm',
    hour: '19:00'
  },
  {
    hour2: '07:30 pm',
    hour: '19:30'
  },
  {
    hour2: '08:00 pm',
    hour: '20:00'
  },
  {
    hour2: '08:30 pm',
    hour: '20:30'
  },
  {
    hour2: '09:00 pm',
    hour: '21:00'
  },
  {
    hour2: '09:30 pm',
    hour: '21:30'
  },
  {
    hour2: '10:00 pm',
    hour: '22:00'
  },
  {
    hour2: '10:30 pm',
    hour: '22:30'
  },
  {
    hour2: '11:00 pm',
    hour: '23:00'
  },
  {
    hour2: '11:30 pm',
    hour: '23:30'
  }
]
