import axios from '@/services/axiosService'

export default {
  async list (params = {}) {
    return axios().get('/service-transaction/list/pending/area', { params })
  },
  accept (id, body) {
    return axios().put(`/service-transaction/accept/${id}`, body)
  },
  count () {
    return axios().get('/service-transaction/count/pending')
  }
}
