import axios from '@/services/axiosService'

export default {
  async list (params = {}) {
    return axios().get('/task-request/list', { params })
  },
  async detail (id) {
    return axios().get(`/task-request/detail/${id}`)
  },
  async count () {
    return axios().get('/task-request/count')
  },
  async create (body) {
    return axios().post('/task-request/create', body)
  },
  async update (id, body) {
    return axios().put(`/task-request/update/${id}`, body)
  },
  async accept (id, body) {
    return axios().put(`/task-request/update/accept/${id}`, body)
  },
  async cancel (id) {
    return axios().put(`/task-request/update/cancel/${id}`)
  },
  async delete (id) {
    return axios().delete(`/task-request/delete/${id}`)
  }
}
