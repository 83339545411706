export default {
  state: {
    pushNotificationData: {
      type: '',
      id: ''
    },
    notificationToken: '',
    taskDate: null,
    numberRequests: 0,
    numberServices: 0
  },
  getters: {
    notificationToken: state => state.notificationToken,
    pushNotificationData: state => state.pushNotificationData,
    taskDate: state => state.taskDate,
    numberRequests: state => state.numberRequests,
    numberServices: state => state.numberServices
  },
  mutations: {
    setNotificationToken (state, payload) {
      state.notificationToken = payload
    },
    setPushNotificationData (state, payload) {
      state.pushNotificationData = payload
    },
    setTaskDate (state, payload) {
      state.taskDate = payload
    },
    setNumberRequests (state, payload) {
      state.numberRequests = payload
    },
    setNumberServices (state, payload) {
      state.numberServices = payload
    }
  },
  actions: {
  }
}
