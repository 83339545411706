// import { setCurrentUser, getCurrentUser } from '.'
import jwt from '@/services/jwtService'
export default (to, from, next) => {
  if (to.matched.some(record => record.meta.loginRequired)) {
    const token = localStorage.getItem('token')
    if (token) {
      const userToken = jwt.decode(token)
      if (Date.now() <= userToken.exp * 1000) {
        // const roleArrayHierarchic = to.matched.filter(x => x.meta.roles).map(x => x.meta.roles);
        // if (roleArrayHierarchic.every(x => x.includes(user.role))) {
        //   next();
        // } else {
        //   next('/unauthorized')
        // }
        next()
      } else {
        localStorage.removeItem('token')
        next('/auth/login')
      }
    } else {
      next('/auth/login')
    }
  } else {
    next()
  }
}
