import axios from 'axios'
import { apiUrl, apiAuthUrl, apiGestionUrl, apiInventoryUrl, permissionKey, apiMaster, apiNotification } from '../constants/config'
/**
 * @description url = 'base' or 'auth'
 * @param data - type object -- { url: string, auth: boolean }
 */
export default (data = {}) => {
  const { url = 'base', auth = true, permission = false } = data
  const options = { base: apiUrl, auth: apiAuthUrl, gestion: apiGestionUrl, inventory: apiInventoryUrl, master: apiMaster, notification: apiNotification }
  const token = localStorage.getItem('token')
  const headers = { ...auth ? { Authorization: `Bearer ${token}` } : '' }
  if (permission) headers.permissionKey = permissionKey
  const instance = axios.create({ baseURL: options[url], headers })
  instance.interceptors.response.use(response => response,
    error => {
      if (error.response.status === 403) {
        localStorage.clear()
        location.reload()
      }
      throw error
    })
  return instance
}
