import { imagesUrl } from '../constants/config'
/**
 * @description Función para esperar unos segundos con await
 * @param {*} ms tiempo en milisegundos
*/
export const waitFor = ms => new Promise((res) => setTimeout(res, ms))

/**
  * @description Función para convertir el formato de hora en AM/PM
  * @param {*} time tiempo en formato 'HH:mm:ss'
*/
export const convertFormat12 = (time) => {
  const hourEnd = time.indexOf(':')
  const H = +time.substr(0, hourEnd)
  let h = H % 12 || 12
  if (h < 10) h = `0${h}`
  const ampm = (H < 12 || H === 24) ? 'AM' : 'PM'
  return `${h}${time.substr(hourEnd, 3)} ${ampm}`
}

/**
  * @description Función para retornar la url de las imagenes
  * @param {*} name nombre de la imagen
  * @param {*} name tamaño de la imagen - lg md original xlg xs xxs
*/
export const imageURL = (name, size) => {
  return `${imagesUrl}${size}/${name}`
}

export const getCurrentUser = () => {
  let user = null
  try {
    user = localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')) : null
  } catch (error) {
    console.log('>>>> src/utils/index.js : getCurrentUser -> error', error)
    user = null
  }
  return user
}

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user))
    } else {
      localStorage.removeItem('user')
    }
  } catch (error) {
    console.log('>>>> src/utils/index.js : setCurrentUser -> error', error)
  }
}
