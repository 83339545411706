import tokenGuard from '@/utils/tokenGuard'
import AuthGuard from '@/utils/auth.guard'
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/app/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "home" */ '../views/app'),
    redirect: '/app',
    meta: { loginRequired: true }
  },
  {
    path: '/app',
    component: () => import(/* webpackChunkName: "about" */ '../views/app'),
    redirect: '/app/tasks',
    meta: { loginRequired: true },
    children: [
      {
        path: '/',
        name: 'home',
        component: HomeView
      },
      {
        path: '/tasks',
        name: 'tasks',
        component: () => import(/* webpackChunkName: "about" */ '../views/app/tasks/IndexPage.vue'),
        redirect: '/tasks/employees',
        children: [
          {
            path: 'list',
            component: () => import('../views/app/tasks/ListPage.vue')
          },
          {
            path: 'employees',
            component: () => import('../views/app/tasks/EmployeesPage.vue')
          },
          {
            path: 'schedule/:id',
            component: () => import('../views/app/tasks/SchedulePage.vue')
          },
          {
            path: 'todo-list',
            component: () => import('../views/app/tasks/TodoListPage.vue')
          },
          {
            path: 'review-inventory/:place/:task',
            component: () => import('../views/app/tasks/ReviewPage.vue')
          }
        ]
      },
      {
        path: '/repetitive-tasks',
        name: 'repetitiveTasks',
        component: () => import(/* webpackChunkName: "about" */ '../views/app/repetitiveTasks/IndexPage.vue'),
        redirect: '/repetitive-tasks/list',
        children: [
          {
            path: 'list',
            component: () => import('../views/app/repetitiveTasks/ListPage.vue')
          },
          {
            path: 'create',
            component: () => import('../views/app/repetitiveTasks/CreatePage.vue')
          },
          {
            path: 'edit/:id',
            component: () => import('../views/app/repetitiveTasks/EditPage.vue')
          }
        ]
      },
      {
        path: '/maintenance',
        name: 'maintenance',
        component: () => import(/* webpackChunkName: "about" */ '../views/app/maintenance/IndexPage.vue'),
        redirect: '/maintenance/list',
        children: [
          {
            path: 'corrective',
            component: () => import('../views/app/maintenance/CorrectivePage.vue')
          },
          {
            path: 'edit/:id',
            component: () => import('../views/app/maintenance/EditPage.vue')
          },
          {
            path: 'qr-scanner',
            component: () => import('../views/app/maintenance/QRScannerPage.vue')
          }
        ]
      },
      {
        path: '/request',
        name: 'request',
        component: () => import(/* webpackChunkName: "about" */ '../views/app/Request/IndexPage.vue'),
        redirect: '/request/list',
        children: [
          {
            path: 'list',
            component: () => import('../views/app/Request/ListPage.vue')
          }
        ]
      },
      {
        path: '/room',
        name: 'room',
        component: () => import(/* webpackChunkName: "about" */ '../views/app/Room/IndexPage.vue'),
        redirect: '/room/list',
        children: [
          {
            path: 'list',
            component: () => import('../views/app/Room/ListPage.vue')
          }
        ]
      },
      {
        path: '/service',
        name: 'service',
        component: () => import(/* webpackChunkName: "about" */ '../views/app/Service/IndexPage.vue'),
        redirect: '/service/list',
        children: [
          {
            path: 'list',
            component: () => import('../views/app/Service/ListPage.vue')
          }
        ]
      },
      {
        path: '/event',
        name: 'event',
        component: () => import(/* webpackChunkName: "about" */ '../views/app/event/IndexPage.vue'),
        redirect: '/event',
        children: [
          {
            path: '',
            component: () => import('../views/app/event/EventPage.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/qr-scanner',
    component: () => import('../views/app/maintenance/QRScannerPage.vue'),
    meta: { loginRequired: true }
  },
  {
    path: '/auth',
    component: () => import(/* webpackChunkName: "user" */ '../views/auth/IndexPage.vue'),
    redirect: '/auth/login',
    children: [
      {
        path: 'login',
        component: () => import(/* webpackChunkName: 'user' */ '../views/auth/LoginPage.vue')
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(tokenGuard)
router.beforeEach(AuthGuard)
export default router
