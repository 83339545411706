<template>
  <router-view/>
</template>

<script>
import pushNotificationService from './services/pushNotificationService'
import { waitFor } from '@/utils/index'
import { mapGetters, mapMutations } from 'vuex'
import apiEmployeeTasksRequest from './api/employeeTasksRequest'
import apiService from './api/service'
import jwt from '@/services/jwtService'
export default {
  name: 'App',
  async mounted () {
    await waitFor(1000)
    await pushNotificationService.registerNotifications()
  },
  computed: {
    ...mapGetters(['pushNotificationData'])
  },
  watch: {
    pushNotificationData (val) {
      if (val) {
        const token = localStorage.getItem('token')
        if (token) {
          this.redirect(val.type, val.id)
        }
      }
    }
  },
  methods: {
    ...mapMutations(['setNumberRequests', 'setNumberServices']),
    redirect (name, id) {
      const route = {
        maintenance: () => {
          this.$router.replace(`/maintenance/edit/${id}`)
        },
        task: () => {
          if (this.$route.path !== '/app') this.$router.push('/app')
        }
      }
      return route[name]()
    }
  },
  created () {
    const token = localStorage.getItem('token')
    if (token) {
      const userToken = jwt.decode(token)
      if (Date.now() <= userToken.exp * 1000) {
        apiEmployeeTasksRequest.count().then(({ data: { data } }) => {
          this.setNumberRequests(data)
        })
        apiService.count().then(({ data }) => {
          this.setNumberServices(data.total)
        })
      }
    }
  }
}
</script>
<style>
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url('./assets/fonts/Montserrat/static/Montserrat-Light.ttf')  format('truetype');
}
</style>
