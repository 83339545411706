import axios from '@/services/axiosService'

export default {
  async listPerWeek (id, params = {}) {
    return axios().get(`/tasks/list/week/${id}`, { params })
  },
  async list (params = {}) {
    return axios().get('/tasks/list-paginate', { params })
  },
  async findBooking (id) {
    return axios().get(`/tasks/booking/${id}`)
  },
  async create (body) {
    return axios().post('/tasks/create', body)
  },
  async update (id, body) {
    return axios().put(`/tasks/update/${id}`, body)
  },
  async updateSubtask (id, body) {
    return axios().put(`/tasks/update/subtask/${id}`, body)
  },
  async updateStatus (id, body) {
    return axios().put(`/tasks/update/status/${id}`, body)
  },
  async delete (id) {
    return axios().delete(`/tasks/delete/${id}`)
  }
}
